<template>
  <section ref="userList">
    <h1>{{ userListTitle }}</h1>

    <v-card ref="admin">
      <dialog-creation-update
          :display.sync="dialogs.creationUpdate.display"
          :is-update="dialogs.creationUpdate.isUpdate"
          :user="dialogs.creationUpdate.user"
          @form-submit="reloadUser"
          @close-dialog="onCloseDialogCreationUpdate"
      />

      <admin-user-table
          :error-message="errorMessage"
          :items="itemsFiltered"
          :loading.sync="loading"
          :search="search"
          @update-user="onDisplayDialogUpdate"
          isResearcherStatus
          @user-status-updated="onUpdateUserStatus"
      >
        <template v-slot:search>
          <v-btn
              text
              large
              @click="dialogs.creationUpdate.display = true"
              v-if="!isResearcherStatus && !loading"
          >
            <u-icon name="nv-user-add"/>
          </v-btn>
          <v-spacer/>
          <v-text-field
              v-if="!loading"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('general.search')"
              single-line
              hide-details
          />
        </template>

        <template v-slot:filter>
          <u-icon name="nv-content-filter" v-if="!loading"/>
          <v-spacer/>
          <v-select
              v-model="filters.groups.values"
              :items="groupItems"
              :label="$t('user.list.datatable.filters.groups')"
              clearable
              multiple
              v-if="!loading"
          />
          <v-spacer/>
          <v-select
              v-model="filters.status.value"
              :items="statusItems"
              :label="$t('user.list.datatable.filters.status')"
              clearable
              v-if="!loading"
          />
          <v-spacer/>
          <v-select
              v-model="filters.region.values"
              :items="regionItems"
              :label="$t('user.list.datatable.filters.region')"
              clearable
              multiple
              v-if="!loading"
          />
        </template>
      </admin-user-table>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { humanProfile, myStatus } from '@/store/modules/User';
import { JWTTranslation } from '@/store/modules/Security';

export default {
  name: 'AdminUserList',

  components: {
    AdminUserTable: () => import('@/components/Admin/UserList/AdminUserTable'),
    DialogCreationUpdate: () =>
        import('@/components/Admin/UserList/DialogCreationUpdate'),
  },

  data: () => ({
    dialogs: {
      creationUpdate: {
        display: false,
        isUpdate: false,
        user: null,
      },
    },
    errorMessage: '',
    items: [],
    loading: false,
    search: '',
    title: '',
    filters: {
      groups: {
        i: 0,
        items: new Map(),
        values: [],
      },
      status: {
        i: 0,
        items: new Map(),
        value: '',
      },
      region: {
        i: 0,
        items: new Map(),
        values: [],
      },
    },
  }),

  computed: {
    ...mapGetters(['groups', 'userConnected', 'users']),
    username () {
      return this.dialogs.deletion.user
             ? this.dialogs.deletion.user.username
             : '';
    },
    itemsFiltered () {
      return this.items.filter(item => {
        if (this.filters.status.value !== '') {
          if (item.status && this.filters.status.value === 'inactive') return false;
          else if (!item.status && this.filters.status.value === 'active') return false;
        }

        if (this.filters.groups.values.length &&
            !item.groupsId.some(userGroup => this.filters.groups.values.includes(userGroup))) {
          return false;
        }

        return !(this.filters.region.values.length && !this.filters.region.values.includes(item.regionId));
      });
    },
    isResearcherStatus () {
      return myStatus(['CHR']);
    },
    userListTitle () {
      return myStatus(['CHR'])
             ? this.$t('user.list.datatable.titles.studyPatients')
             : myStatus(['MED'])
               ? this.$t('user.list.datatable.titles.patients')
               : this.$t('user.list.datatable.titles.users');
    },
    groupItems () {
      return this.filters.groups.i > 0
             ? [...this.filters.groups.items].map(([, value]) => value)
             : [];
    },
    regionItems () {
      return this.filters.region.i > 0
             ? [...this.filters.region.items].map(([, value]) => value)
             : [];
    },
    statusItems () {
      return this.filters.status.i > 0
             ? [...this.filters.status.items].map(([, value]) => value)
             : [];
    },
  },

  watch: {
    userConnected: {
      deep: true,
      handler: function () {
        this.reloadUser();
      },
    },
  },

  created () {
    this.reloadUser();
    this.$emit('title');
  },

  methods: {
    ...mapActions(['loadGroups', 'loadUsers']),
    /**
     * Return the referent or undefined if the referent value is null in BDD
     *
     * @param referent
     * @returns {undefined|UserOnirosJS}
     */
    getReferent ({ referent }) {
      return referent === null
             ? undefined
             : this.users.find(referentUser => referentUser.id === referent);
    },
    loaded () {
      this.errorMessage = '';
      this.items.splice(0, this.items.length);

      this.users.forEach(user => {
        const {
          username,
          firstName,
          lastName,
          email,
          phone,
          region,
          isActive,
          id,
          groups,
          studies,
          referent,
          included,
        } = user;

        this.items.push({
          id,
          username,
          firstName,
          lastName,
          email,
          phone: phone,
          referent: this.referentName(referent),
          referentId: referent ? referent.id : null,
          region: region ? region.label : '',
          regionId: region ? region.code : '',
          status: isActive,
          groups: groups.reduce((acc, group, i) => {
            if (i === 0) {
              return humanProfile(group.name);
            }

            return `${acc} - ${humanProfile(group.name)}`;
          }, ''),
          groupsId: groups.map(group => group.id),
          studies,
          included: included,
        });

        if (region) {
          this.filters.region.items.set(region.code, {
            value: region.code,
            text: region.label,
          });
        }

        groups.forEach(group => {
          this.filters.groups.items.set(group.id, {
            value: group.id,
            text: humanProfile(group.name),
          });
        });
      });

      this.filters.status.items.set('inactive', {
        value: 'inactive',
        text: this.$t('user.list.datatable.filters.filterStatus.inactive'),
      });
      this.filters.status.items.set('active', {
        value: 'active',
        text: this.$t('user.list.datatable.filters.filterStatus.active'),
      });

      this.filters.status.i++;
      this.filters.groups.i++;
      this.filters.region.i++;

      this.loading = false;
      this.onCloseDialogCreationUpdate();
    },
    onCloseDialogCreationUpdate () {
      this.dialogs.creationUpdate.display = false;
      this.dialogs.creationUpdate.isUpdate = false;
      this.dialogs.creationUpdate.user = null;
    },
    onDisplayDialogUpdate (user) {
      this.dialogs.creationUpdate.user = Object.assign({}, user);
      this.dialogs.creationUpdate.display = true;
      this.dialogs.creationUpdate.isUpdate = true;
    },
    onUpdateUserStatus ({ userUpdated, newStatus }) {
      this.items.find(user => user.id === userUpdated.id).status = newStatus;
    },

    referentName (referent) {
      if (this.groups.length) {
        if (referent) {
          const isDoc = referent.groups.find(group => group.name === 'MED');
          return `${isDoc ? 'Dr. ' : ''}${
              referent.lastName ? referent.lastName : ''
          } ${referent.firstName ? referent.firstName[0].toUpperCase() : ''}.`;
        }

        return '';
      }

      return '';
    },
    reloadUser () {
      this.loading = true;
      let options = {};

      if (this.userConnected.groupSelected && this.userConnected.studySelected) {
        if (this.userConnected.groupSelected.name === 'MED') {
          const jwt = localStorage.getItem('JWT_ACCESS');
          if (jwt) {
            options.referentId = JWTTranslation(jwt).body.userId;
          }
        } else if (this.userConnected.groupSelected.name === 'CHR') {
          options.groupName = 'PAT';
        }

        options.studyId = this.userConnected.studySelected.id;

        if (options.studyId) {
          Promise.all([this.loadUsers(options), this.loadGroups()])
                 .then(this.loaded);
        }
      } else {
        this.errorMessage = this.$t('user.list.datatable.errorMessage');
        this.loading = false;
      }
    },
  },
};
</script>
